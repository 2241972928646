import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, Route, NavLink, Link as NormalLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import * as Cookies from 'js-cookie';
import * as userActions from '../../store/actions';
import {
    getShopUrl,
    getIncomingFrom,
    getURLForIncomingFrom,
    getNameForIncomingFrom,
    parseAutomaticLogin,
} from '../functions';
import config from '../../config';
import i18n from '../../i18n';

import { Logo } from '../../common';
import { User, Menu } from '../Icons';

const Wrapper = styled.header`
    box-shadow: 0px 2px 7px rgba(0,0,0,0.5);
    position: relative;
    z-index: 1;
`;

const Appbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    padding: 0.5em 36px;
    background-color: ${(props) => props.theme.background};

    @media (max-width: 700px) {
        padding: 0.5em;
    }
`;

const RightContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    flex-shrink: 0;
    justify-content: flex-end;

    @media (max-width: 1210px) {
        display: none;
    }
`;

const UserIconStyled = styled(User)`
    fill: ${(props) => props.theme.primaryText};
    height: 80%;
`;

const Button = styled.div`
    background-color: rgb(255,255,255);
    padding: 10px 30px 10px 30px;
    font-weight: bold;
    border: 0px;
    border-radius: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.primaryDark};
    text-transform: uppercase;
    margin-right: 10px;
`;

const Login = styled(Button)`
`;

const Logout = styled(Button)`
`;

const Register = styled(Button)`
`;

const Navigation = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background: ${(props) => props.theme.background};
    @media (max-width: 1210px) {
        display: none;
    }
`;

const Link = styled(NavLink)`
    text-decoration: none;
    letter-spacing: 0em;
    white-space: nowrap;
    color: ${(props) => props.theme.text};
    margin: 0 2em;
    font-family: "Titillium Web";
    font-weight: 500;
    font-size: 14pt;
    border-bottom: 5px transparent solid;
    &.active {
        text-decoration: underline;
    }
`;

const ExternalLink = styled.a`
    text-decoration: none;
    letter-spacing: 0em;
    white-space: nowrap;
    color: ${(props) => props.theme.text};
    margin: 0 2em;
    font-family: "Titillium Web";
    font-weight: 500;
    font-size: 14pt;
    cursor: pointer;
    border-bottom: 5px transparent solid;
    &.active {
        text-decoration: underline;
    }
`;

const BackToLink = styled(ExternalLink)`
    margin: 0 auto;
`;

const MobileMenuIcon = styled(Menu)`
    margin-left: 1em;
    cursor: pointer;
    fill: ${(props) => props.theme.primary};
    @media (min-width: 1211px) {
        display: none;
    }
`;

const Header = ({ history, location, user: { authenticated, first_name, last_name, cart }, userActions: { login, logout, refreshAccounts, register, openMenu }, ...props }) => {
    const [ incomingFrom, setIncomingFrom ] = useState();

    useEffect(() => {
        setIncomingFrom(getIncomingFrom(location));
        if(!authenticated && parseAutomaticLogin(location)) {
            login();
        }
    }, [location]);

    return (
        <Wrapper>
            <Appbar {...props}>
                {incomingFrom && (
                    <BackToLink
                        onClick={() => {
                            const backToURL = getURLForIncomingFrom(true, authenticated);
                            if(backToURL) {
                                window.location.replace(backToURL);
                            }
                        }}
                    >
                        &lt;&lt; {getNameForIncomingFrom()}
                    </BackToLink>
                )}

                {
                    !incomingFrom &&
                        <NormalLink to='/' style={{ height: '100%' }}>
                            <Logo />
                        </NormalLink>
                }
                <Switch>
                    <Route component={() => (
                        !incomingFrom && (
                            <Navigation>
                                <Link exact to='/'>
                                    Etusivu
                                </Link>
                                {config.playableTicketsEnabled &&
                                 <Link to='/pelattavat'>
                                     Pelaa arpoja
                                 </Link>
                                }
                                <Link to='/lunastettavat'>
                                    {config.playableTicketsEnabled && 'Muut arvat' || 'Arvat'}
                                </Link>

                                <Link
                                    to='/voittokauppa'
                                    onClick={() => authenticated && refreshAccounts()}
                                >
                                    Voittokauppa
                                </Link>

                                {authenticated && (
                                    <Link
                                        to='/tili'
                                        onClick={() => authenticated && refreshAccounts()}
                                    >
                                        Oma tili
                                    </Link>
                                )}
                            </Navigation>
                        )
                    )} />
                </Switch>
                <RightContainer>
                    {!incomingFrom ? (
                        (authenticated ? (
                            <>
                                <Logout onClick={() => logout()}>
                                    {i18n.gettext('logout')}
                                </Logout>
                                <Avatar
                                    color='#8e5adf'
                                    name={first_name + ' ' + last_name}
                                    round={true}
                                    size='40px'
                                    style={{ fontWeight: 'bold' }}
                                />
                            </>
                        ) : (
                            <>
                                <Register onClick={() => register()}>
                                    Uusi tili
                                </Register>
                                <Login onClick={() => login()}>
                                    {i18n.gettext('login')}
                                </Login>
                                <UserIconStyled />
                            </>
                        ))
                    ) : null}
                </RightContainer>
                {!incomingFrom ? (
                    <MobileMenuIcon onClick={() => openMenu()}/>
                ): (
                    (authenticated && (
                        <Link
                            to='/tili'
                            onClick={() => authenticated && refreshAccounts()}
                        >
                            Oma tili
                        </Link>
                    ))
                )}
            </Appbar>
        </Wrapper>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch)
    };
};

const ConnectedHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

export { ConnectedHeader as Header };
