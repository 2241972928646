import React, { useContext, useEffect, useState } from 'react';
import { Switch, Route, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeContext } from 'styled-components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../store/appActions';
import * as userActions from '../store/actions';
import { Page, Footer } from '../common';
import { formatCurrency } from '../common/functions';
import { DepositBonusContainer } from '../common/DepositBonus';

import {
    AccountEvents,
    AddSaldo,
    AddSaldoSuccess,
    GameHistory,
    Info,
    Limits,
    Winnings,
    Orders,
    Saldos,
} from '.';

import { TopContainer } from './TopContainer';

import config from '../config';

const Menu = styled.div`
    display: flex;
    flex-flow: column;
    grid-template-columns: 1fr;
    align-items: center;
    background-color: ${(props) => props.theme.text};
    margin-top: 2em;

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        margin-top: 0em;
    }
`;
const Link = styled(NavLink)`
    text-align: center;
    text-decoration: none;

    font-family: "Titillium Web";
    font-weight: 500;
    font-size: 14pt;
    letter-spacing: 0.1em;
    white-space: nowrap;
    color: ${(props) => props.theme.secondaryText};
    margin: 5px 2em;
    padding: 10px;

    width: 100%;
    &.active {
        text-decoration: underline;
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        font-size: 1.2em;

        padding-bottom: 0px;

        :last-child {
            padding-bottom: 10px;
        }
    }
`;

const SaldoContainer = styled.div`
    background-color: ${(props) => props.theme.tertiary};
    border-radius: 0px;
    color: white;
    display: flex;
    flex-flow: column;
    font-weight: 400;
    padding: 10px;
    padding-bottom: 2em;
`;

const SaldoTexts = styled.div`
    text-align: center;
    font-size: 1.4em;

    h3 {
        font-weight: 400;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-left: 1em;
        margin-right: 1em;
    }

    span {
        font-weight: 600;
        font-size: 1.6em;
    }

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        font-size: 1.2em;
    }
`;

const LeftContainer = styled.div`
    background-color: ${(props) => props.theme.text}
    margin: 2em;
    box-shadow: 0px 2px 7px rgba(0,0,0,0.5);
`;

const RightContainer = styled.div`
    vertical-align: middle;
    width: 100%;
    height: 100%;
    background-color: ${(props) => props.theme.text}
    margin: 2em;
    box-shadow: 0px 2px 7px rgba(0,0,0,0.5);

    @media (max-width: ${(props) => props.theme.mobileLimit}) {
        width: unset;
    }
`;

const ProductWinNote = styled.div`
    font-size: 80%;
    margin-top: 2em;
    justify-content: center !important;
`;

const reduceByVariety = (accounts, variety) => {
    return accounts
        .filter((item) => item.variety === variety)
        .reduce((acc, item) => {
            return acc + item.saldo;
        }, 0);
};

const Account = ({
    history,
    app: {
        cms,
        myBonuses
    },
    appActions: {
        getBonusSelf
    },
    user,
    userActions: {
        updateUser,
        refreshAccounts
    }
}) => {
    const themeContext = useContext(ThemeContext);

    const [winsBalance, setWinsBalance] = useState(null);
    const [extraWinsBalance, setExtraWinsBalance] = useState(null);
    const [moneyBalance, setMoneyBalance] = useState(null);
    const [demoBalance, setDemoBalance] = useState(null);
    const [gameCurrencyBalance, setGameCurrencyBalance] = useState(null);

    useEffect(() => {
        setWinsBalance(reduceByVariety(user.accounts, 'wins'));
        setExtraWinsBalance(reduceByVariety(user.accounts, 'extra_wins'));
        setMoneyBalance(reduceByVariety(user.accounts, 'money'));
        setDemoBalance(reduceByVariety(user.accounts, 'bonus'));
        setGameCurrencyBalance(reduceByVariety(user.accounts, 'game_currency'));
    }, [user.accounts]);

    useEffect(() => {
        const elem = document.getElementById('accountPageContent');
        if(elem) {
            elem.scrollTo(0,0);
        }
    });

    useEffect(() => {
        getBonusSelf();
    }, [getBonusSelf]);

    return (
        <Page>
            <Page.Content id='accountPageContent' className='pageContent' bgColor={themeContext.darkroom}>
                <TopContainer className='topContainer'>
                    <LeftContainer>
                        <SaldoContainer>
                            <SaldoTexts>
                                <h3>Saldosi</h3>
                                <div>
                                    Voitot:&nbsp;<span>{formatCurrency(winsBalance)} € *</span>
                                </div>
                                {config.playableTicketsEnabled &&
                                 <div>
                                     Lisävoitot:&nbsp;<span>{formatCurrency(extraWinsBalance)} € *</span>
                                 </div>
                                }
                                <div>
                                    Raha:&nbsp;<span>{formatCurrency(moneyBalance)} €</span>
                                </div>
                                {(demoBalance > 0) && (
                                    <div>
                                        Ostobonus:&nbsp;<span>{formatCurrency(demoBalance)} €</span>
                                    </div>
                                )}
                                {(gameCurrencyBalance > 0) && (
                                    <div>
                                        Pelibonus:&nbsp;<span>{formatCurrency(gameCurrencyBalance)} €</span>
                                    </div>
                                )}
                                <ProductWinNote>* tavaravoittoarvoa</ProductWinNote>
                            </SaldoTexts>
                        </SaldoContainer>
                        <Menu>
                            <Link to='/tili' exact>
                                Profiili
                            </Link>
                            {config.playableTicketsEnabled &&
                             <Link to='/tili/lisaa-saldoa'>
                                 Lisää saldoa
                             </Link>
                            }
                            <Link to='/tili/tilisaldot'>
                                Tilien saldot
                            </Link>
                            {config.playableTicketsEnabled &&
                             <Link to='/tili/pelihistoria'>
                                 Pelihistoria
                             </Link>
                            }
                            <Link to='/tili/tilaukset'>
                                Muut voittotapahtumat
                            </Link>
                            <Link to='/tili/voittohistoria'>
                                Arpojen rekisteröinnit
                            </Link>
                            <Link to='/tili/tilitapahtumat'>
                                Tilitapahtumat
                            </Link>
                            {config.playableTicketsEnabled &&
                             <Link to='/tili/pelirajat'>
                                 Pelirajat
                             </Link>
                            }
                        </Menu>
                    </LeftContainer>

                    <RightContainer className='rightContainer'>
                        <DepositBonusContainer cms={ cms } myBonuses={ myBonuses } />
                        <Switch>
                            <Route path='/tili' exact component={Info} />
                            {config.playableTicketsEnabled &&
                             <>
                                 <Route path='/tili/lisaa-saldoa/success' component={AddSaldoSuccess} />
                                 <Route path='/tili/lisaa-saldoa' exact component={AddSaldo} />
                             </>
                            }
                            <Route path='/tili/tilisaldot' exact component={Saldos} />
                            <Route path='/tili/pelihistoria' component={GameHistory} />
                            <Route path='/tili/pelirajat' component={Limits} />
                            <Route path='/tili/voittohistoria' component={Winnings} />
                            <Route path='/tili/tilaukset' component={Orders} />
                            <Route path='/tili/tilitapahtumat' component={AccountEvents} />
                        </Switch>
                    </RightContainer>

                </TopContainer>
                <Footer />
            </Page.Content>
        </Page>
    );
};

const mapStateToProps = (state) => {
    return {
        app: state.app,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        userActions: bindActionCreators({ ...userActions }, dispatch),
        appActions: bindActionCreators({ ...appActions }, dispatch),
    };
};

const ConnectedAccount = connect(mapStateToProps, mapDispatchToProps)(Account);

export { ConnectedAccount as Account };
