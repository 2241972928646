import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Card } from '../common';
import { getShopUrl } from '../common/functions';

import Paperiarpa from '../images/paperiarpa.png';
import PelattavaArpa from'../images/pelattava-arpa.png';
import Voitot from '../images/voitot.png';

import config from '../config';

const Grid = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9%, 1fr));
    grid-gap: 30px;
    margin: 0 auto;
    margin-top: 3em;
    margin-bottom: 3em;
    min-height: 300px;
    max-width: 1500px;
    padding-left: 20px;
    padding-right: 20px;

    > div {
        min-width: 250px;
    }

    @media (max-width: 1000px) {
        grid-template-columns: 1fr;
        width: 100%;
        padding: 5%;
        margin: auto;

        > div {
            min-height: 400px;
        }
    }
`;

const Cards = ({
    cms,
    history,
    user: { authenticated }
}) => {
    return (
        <Grid>
            <Card noGradient={true}>
                <Card.Image image={PelattavaArpa}/>
                <Card.Title bg='transparent'>
                    {
                        cms &&
                        cms.landingPage &&
                        cms.landingPage.cards &&
                        cms.landingPage.cards[0].title
                    }
                </Card.Title>
                <Card.Text color='#000'>
                    {
                        cms &&
                        cms.landingPage &&
                        cms.landingPage.cards &&
                        cms.landingPage.cards[0].text
                    }
                </Card.Text>
                <Card.Button
                    onClick={() => history.push('/lunastettavat')}
                >
                    Rekisteröi arpoja
                </Card.Button>
            </Card>
            {config.playableTicketsEnabled &&
             <Card noGradient={true}>
                 <Card.Image image={Paperiarpa}/>
                 <Card.Title bg='transparent'>
                     {
                         cms &&
                             cms.landingPage &&
                             cms.landingPage.cards &&
                             cms.landingPage.cards[1].title
                     }
                 </Card.Title>
                 <Card.Text color='#000'>
                     {
                         cms &&
                             cms.landingPage &&
                             cms.landingPage.cards &&
                             cms.landingPage.cards[1].text
                     }
                 </Card.Text>
                 <Card.Button
                     onClick={() => history.push('/pelattavat')}
                 >
                     Pelaa arpa
                 </Card.Button>
             </Card>
            }
            <Card noGradient={true}>
                <Card.Image image={Voitot}/>
                <Card.Title bg='transparent'>
                    {
                        cms &&
                        cms.landingPage &&
                        cms.landingPage.cards &&
                        cms.landingPage.cards[2].title
                    }
                </Card.Title>
                <Card.Text color='#000'>
                    {
                        cms &&
                        cms.landingPage &&
                        cms.landingPage.cards &&
                        cms.landingPage.cards[2].text
                    }
                </Card.Text>
                <Card.Button
                    onClick={() => history.push('/voittokauppa')}
                >
                    Katso
                </Card.Button>
            </Card>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const ConnectedCards = connect(mapStateToProps)(Cards);

export { ConnectedCards as Cards };
